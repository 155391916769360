<template>
  <v-container
    fluid
    class="pa-0"
  >
    <div
      class="section1 small"
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
          class="mt-16"
        >
          <v-spacer></v-spacer>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="ml-16 mt-16">
          <div
            class="travel-text mt-0"
          >
            Who should buy <br> travel insurance?
          </div>
        </v-col>
      </v-row>
    </div>
    <div class="section2">
      <v-row>
        <v-col class="mt-16">
          <v-spacer class="mt-16"></v-spacer>
        </v-col>
      </v-row>
    </div>
    <div class="section3">
      <v-row>
        <v-col cols="6">
          <div class="insurance mt-10 ml-16">
            Travel Insurance
          </div>
        </v-col>
      </v-row>
      <v-row class="mb-16">
        <v-col cols="8">
          <div class="sec3-sub mt-10 ml-16 pl-16 pb-16">
            Get travel insurance and assistance designed to help protect your vacation investment and travelers whenever and wherever you need us. Travel Smarter™ with Generali Global Assistance and their 30-year record of outstanding customer service that you can trust.
          </div>
        </v-col>
      </v-row>
      <v-img
        src="/img/covid-coverage.png"
        class="covid-coverage-small"
      ></v-img>
      <v-img
        src="/img/10day-free.png"
        class="free-look-small"
      ></v-img>
    </div>
    <div class="section4 mt-n16 pb-16">
      <v-row class="pt-16 pb-16">
        <v-col
          cols="6"
          class="ml-8 mt-16"
        >
          <v-img
            src="/img/travel-fam.png"
            class="travel-fam"
          ></v-img>
        </v-col>
        <v-col
          cols="5"
          class="mt-16 pt-16"
        >
          <div class="free-look pt-16">
            What is our free look period? <br> <br>
          </div>
          <v-row>
            <v-col>
              <div class="free-look-txt pb-16">
                Your satisfaction with your plan purchase is our goal. From the time of purchase, every travel insurance plan includes a designated free look period in which you may cancel your plan for a full refund if it doesn't suit your needs. <br> <br>
                For most states of residence, you may cancel your plan within 10 days from the time of purchase (state restrictions apply). If you live in Indiana, the free look period gives you 30 days to decide if your plan is right for you. <br> <br>
                Keep in mind, you can only cancel your plan within the free look period as long as you have not filed a claim, or left on your trip.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div class="section5 mb-n16">
        <div class="clip"></div>
        <v-row justify="center">
          <v-col
            cols="8"
            class="d-flex justify-center"
          >
            <div class="btm-txt">
              Blanket’s Canadian travel partner has its policies underwritten by the following insurers: Industrial Alliance Insurance and Financial Services Inc. Industrial Alliance Pacific General Insurance Corporation .
            </div>
          </v-col>
        </v-row>
      </div>
    </div>
  </v-container>
</template>

<script>
  export default {

  }
</script>

<style lang="scss" scoped>
.section1{
  position: relative;
  width: 100%;
  background: url("../../../../public/img/travelusa-learn-background.png") no-repeat;
  background-size: cover;
}

.section2 {
    background-color: #FFFFFF;
}

.section3 {
    background-color: #DEECF1;
}

.section4 {
    background-color: #FFFFFF;
}

.covid-coverage-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 75rem;
}

.covid-coverage-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 0;
    top: 90rem;
}

.free-look-small {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 75rem;
}

.free-look-large {
    position: absolute;
    max-width: 300px;
    height: auto;
    object-fit: scale-down;
    right: 16rem;
    top: 90rem;
}

.sec3-sub {
font-size: 28px;
line-height: 132.19%;
color: #757575;
}

.travel-text {
font-style: normal;
font-weight: normal;
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;

color: #616161;
}

.insurance {
font-size: 60px;
line-height: 70px;
letter-spacing: -0.005em;
color: #757575;
}

.ins-card {
    background-color: #EEEEEE;
    width: 425px;
    height: 252px;
}

.learn {
  border: 1px solid #00A1B7 !important;
}

.sec4-txt {
    font-size: 55px;
line-height: 64px;
text-align: center;
letter-spacing: -0.005em;
color: #757575;
}

.plan-img {
    max-width: 155px;
    height: auto;
    object-fit: scale-down;
}

.coverage-img {
    max-width: 130px;
    height: auto;
    object-fit: scale-down;
}

.payout-img {
    max-width: 145px;
    height: auto;
    object-fit: scale-down;
}

.customercare-img {
    max-width: 136px;
    height: 119px;
    object-fit: scale-down;
}
.sec4-subtxt {
font-weight: 500;
font-size: 24px;
line-height: 32px;
text-align: center;
text-transform: capitalize;
color: #212121;
}

.sec4-info {
    font-size: 20px;
    line-height: 33px;
    text-align: center;
    font-feature-settings: 'liga' off;
    color: #757575;
}

.section5 {
    height: 500px;
    background: rgba(216, 253, 215, 0.5);
}

.small {
    height: 700px;
}

.large {
    height: 900px;
}

.travel-fam {
    max-width: 754px;
    height: auto;
    object-fit: scale-down;
}

.free-look {
    font-weight: 500;
font-size: 36px;
line-height: 42px;
color: #757575;
}

.free-look-txt {
font-size: 24px;
line-height: 33px;
font-feature-settings: 'liga' off;
color: #757575;
}

.btm-txt {
    font-size: 20px;
line-height: 26px;
text-align: center;
color: #757575;
}

.clip {
  height: 200px;
  background-color: #FFFFFF;
  clip-path: ellipse(50% 16% at 50% 0%);
}
</style>
